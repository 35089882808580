import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
	interface Window {
		fbq: (argument1: string, argument2: string, argument3: Record<string, any>) => void;
	}
}

type Props = {
	id: string;
};

const FacebookPixel = ({ id }: Props) => {
	const router = useRouter();

	useEffect(() => {
		if (typeof window === 'undefined') return;

		const fbqData = {
			/**
			 * Facebook Event details => (init: id)
			 *
			 */
		};

		if (window.fbq) window.fbq('init', `${id}`, fbqData);
	}, [id]);

	useEffect(() => {
		const pageView = () => {
			if (typeof window === 'undefined') return;

			const fbqData = {
				/**
				 * Facebook Event details => (track: PageView)
				 *
				 */
			};

			if (window.fbq) window.fbq('track', 'PageView', fbqData);
		};

		router.events.on('routeChangeComplete', pageView);

		return () => {
			router.events.off('routeChangeComplete', pageView);
		};
	}, [router.events]);

	return (
		<Script id="facebook-pixel">
			{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            `}
		</Script>
	);
};

export default FacebookPixel;
