import { useAuthStore } from '@/stores/auth';
import { generateHash } from '@/utilities/text';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
	interface Window {
		snaptr: (argument1: string, argument2: string, argument3: Record<string, any>) => void;
	}
}

type Props = {
	id: string;
};

const SnapchatPixel = ({ id }: Props) => {
	const router = useRouter();
	const { session } = useAuthStore();

	useEffect(() => {
		(async () => {
			const snaptrData = {
				...(session && {
					user_email: session.user.email,
					user_hashed_email: await generateHash(session.user.email)
				})

				/**
				 * Snapchat Event details => (init: id)
				 *
				 * 01. ❌ ip_address: 'INSERT_IP_ADDRESS'
				 * 02. ✅ user_email: 'INSERT_USER_EMAIL'
				 * 03. ❌ user_phone_number: 'INSERT_USER_PHONE_NUMBER'
				 * 04. ✅ user_hashed_email: 'INSERT_USER_HASHED_EMAIL'
				 * 05. ❌ user_hashed_phone_number: 'INSERT_USER_HASHED_PHONE_NUMBER'
				 */
			};

			if (window.snaptr) window.snaptr('init', id, snaptrData);
		})();
	}, [id, session]);

	useEffect(() => {
		const handleSnapchatPageEvent = async () => {
			if (typeof window === 'undefined') return;

			const snaptrData = {
				...(session && {
					user_email: session.user.email,
					user_hashed_email: await generateHash(session.user.email)
				})

				/**
				 * Snapchat Event details => (PAGE_VIEW)
				 *
				 * 01. ❌ item_ids: ['INSERT_ITEM_ID_1', 'INSERT_ITEM_ID_2']
				 * 02. ❌ item_category: 'INSERT_ITEM_CATEGORY'
				 * 03. ❌ uuid_c1: 'INSERT_UUID_C1'
				 * 04. ✅ user_email: 'INSERT_USER_EMAIL'
				 * 05. ❌ user_phone_number: 'INSERT_USER_PHONE_NUMBER'
				 * 06. ✅ user_hashed_email: 'INSERT_USER_HASHED_EMAIL'
				 * 07. ❌ user_hashed_phone_number: 'INSERT_USER_HASHED_PHONE_NUMBER'
				 */
			};

			if (window.snaptr) window.snaptr('track', 'PAGE_VIEW', snaptrData);
		};

		router.events.on('routeChangeComplete', handleSnapchatPageEvent);

		return () => {
			router.events.off('routeChangeComplete', handleSnapchatPageEvent);
		};
	}, [router.events, session]);

	return (
		<Script id="snapchat-pixel" async>
			{`(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                \'https://sc-static.net/scevent.min.js\');`}
		</Script>
	);
};

export default SnapchatPixel;
