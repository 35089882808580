import { Languages } from '@/enums/languages';
import { PageTitle } from '@/types/page';
import { Text } from '@/types/text';
import useTranslation from 'next-translate/useTranslation';

const useDynamicTranslate = () => {
	const { t, lang } = useTranslation('common');
	const isEnglish = lang === Languages.ENGLISH;

	const translation = (payload: undefined | Text[]): string => {
		if (!payload || payload.length === 0) return '';
		const result = payload.find((item) => item.lang === lang);
		return result ? result.text : payload[0] ? payload[0].text : t('add_translation');
	};

	const titleTranslation = (payload: undefined | PageTitle[]): string => {
		if (!payload || payload.length === 0) return '';
		const result = payload.find((item) => item.lang === lang);
		return result ? result.title : payload[0] ? payload[0].title : t('add_translation');
	};

	return { isEnglish, translation, titleTranslation };
};

export default useDynamicTranslate;
