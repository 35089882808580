import { Session } from '@/types/session';
import { create } from 'zustand';

type State = {
	session: null | Session;
	setSession: (session: null | Session) => void;
};

export const useAuthStore = create<State>((set) => ({
	session: null,
	setSession(session) {
		set((state) => ({ session }));
	}
}));
