export const capitalizeText = (text: string): string => {
	return text.charAt(0).toUpperCase().concat(text.slice(1).toLowerCase());
};

export const toTitleCase = (text: string): string => {
	return text
		.toLowerCase()
		.split(' ')
		.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const camelCaseSlug = (text: string): string => {
	return text
		.toLowerCase()
		.split('-')
		.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
		.join('');
};

export const convertToSlug = (text: string): string => {
	return text
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '');
};

export const clipText = (text: string, limit: number) => {
	const clippedText = text.substring(0, limit);

	if (clippedText.length >= limit) return `${clippedText}...`;
	return clippedText;
};

export const generateHash = async (text: string) => {
	// encode as UTF-8
	const textBuffer = new TextEncoder().encode(text);

	// hash the message
	const hashBuffer = await crypto.subtle.digest('SHA-256', textBuffer);

	// convert ArrayBuffer to Array
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// convert bytes to hex string
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

	return hashHex;
};
