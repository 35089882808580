import FacebookPixel from '@/components/Analytics/FacebookPixel';
import SnapchatPixel from '@/components/Analytics/SnapchatPixel';
import useDynamicTranslate from '@/hooks/useDynamicTranslate';
import { createEmotionCache } from '@/utilities/theme';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import '@styles/app.css';
import '@styles/phone-input.css';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextComponentType } from 'next';
import { LoaderConfig } from 'next-translate';
import appWithI18n from 'next-translate/appWithI18n';
import { AppProps } from 'next/app';
import Head from 'next/head';
import 'swiper/css/a11y';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import i18nConfig from '../i18n';

export type Props = AppProps & { emotionCache?: EmotionCache };

const App = ({ Component, emotionCache, pageProps, router }: Props) => {
	const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });
	const clientSideEmotionCache = createEmotionCache(router.locale);
	emotionCache = clientSideEmotionCache;
	const { translation } = useDynamicTranslate();

	const PAGE_URL = typeof window !== 'undefined' ? window.location.href : undefined;
	const FACEBOOK_PIXEL_ID = process.env.NODE_ENV === 'production' ? '5197122033732944' : 'FACEBOOK_TEST_ID';
	const SNAPCHAT_PIXEL_ID = process.env.NODE_ENV === 'production' ? '375b5c9d-84c7-4089-834c-5fdc5bf9cdd5' : 'FACEBOOK_TEST_ID';

	return (
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<CacheProvider value={emotionCache}>
					<Head>
						<title>{translation(pageProps.domain?.website_name)}</title>
						<link rel="canonical" href={PAGE_URL} />
						<link rel="icon" href={pageProps.domain?.website_favicon || '/favicon.ico'} sizes="any" />
						<link rel="apple-touch-icon" href={pageProps.domain?.website_favicon || '/apple-touch-icon.png'} />
						<meta name="viewport" content="initial-scale=1, width=device-width" />
						<meta name="description" content={translation(pageProps.domain?.website_description)} />
						<meta name="theme-color" content={pageProps.domain?.palette_primary} />
						<meta property="og:description" content={translation(pageProps.domain?.website_description)} />
						<meta property="og:title" content={translation(pageProps.domain?.website_name)} />
						<meta property="og:url" content={PAGE_URL} />
					</Head>
					<CssBaseline />
					<FacebookPixel id={FACEBOOK_PIXEL_ID} />
					<SnapchatPixel id={SNAPCHAT_PIXEL_ID} />
					<Component {...pageProps} />
				</CacheProvider>
			</Hydrate>
		</QueryClientProvider>
	);
};

export default appWithI18n(App as NextComponentType<any>, { ...i18nConfig, skipInitialProps: true } as LoaderConfig);
